export function initializeFormValidation(formSelector) {
		const form = document.querySelector(formSelector);
					if (form) {
					// Validation du titre
					const modelInput = document.querySelector('#bicycle_model');
					if (modelInput) {
						modelInput.addEventListener('input', function() {
							const errorDiv = this.nextElementSibling || createErrorDiv(this);
							if (this.value.length > 25) {
								showError(errorDiv, 'Le titre ne doit pas dépasser 25 caractères');
							} else {
								hideError(errorDiv);
							}
						});
					}

					// Validation du prix
					const priceInput = document.querySelector('#price_per_hour');
					if (priceInput) {
						priceInput.addEventListener('input', function() {
							const errorDiv = this.nextElementSibling || createErrorDiv(this);
							const price = parseFloat(this.value);
							if (price > 100) {
								showError(errorDiv, 'Le prix ne peut pas dépasser 100€ par jour');
							} else {
								hideError(errorDiv);
							}
						});
					}

					// Validation de la caution
					const cautionInput = document.querySelector('#bicycle_caution');
					if (cautionInput) {
						cautionInput.addEventListener('input', function() {
							const errorDiv = this.nextElementSibling || createErrorDiv(this);
							const caution = parseFloat(this.value);
							if (caution < 20 || caution > 3000) {
								showError(errorDiv, 'La caution doit être comprise entre 20€ et 3000€');
							} else {
								hideError(errorDiv);
							}
						});
					}

					// Validation de la marque
					const brandInput = document.querySelector('#bicycle_brand');
					if (brandInput) {
						brandInput.addEventListener('input', function() {
							const errorDiv = this.nextElementSibling || createErrorDiv(this);
							if (this.value.length > 15) {
								showError(errorDiv, 'La marque ne doit pas dépasser 15 caractères');
							} else {
								hideError(errorDiv);
							}
						});
					}

					// Validation du numéro d'identification
				const idInput = document.querySelector('#bicycle_identification_number');
					if (idInput) {
						idInput.addEventListener('input', function() {
							const errorDiv = this.nextElementSibling?.classList.contains('validation-error')
								? this.nextElementSibling
								: createErrorDiv(this);
							if (this.value.length > 25) {
								showError(errorDiv, 'Le numéro d\'identification ne doit pas dépasser 25 caractères');
							} else {
								hideError(errorDiv);
							}
						});
					}
					form.addEventListener('submit', function(event) {
						const errors = [];
					
					// Vérification du titre
					const model = document.querySelector('#bicycle_model').value;
					if (model.length > 25) {
						errors.push('Le titre ne doit pas dépasser 25 caractères');
					}
			
					// Vérification du prix
					const price = parseFloat(document.querySelector('#price_per_hour').value);
					if (price > 100) {
						errors.push('Le prix ne peut pas dépasser 100€ par jour');
					}
			
					// Vérification de la caution
					const caution = parseFloat(document.querySelector('#bicycle_caution').value);
					if (caution < 20 || caution > 3000) {
						errors.push('La caution doit être comprise entre 20€ et 3000€');
					}
			
					// Vérification de la marque
					const brand = document.querySelector('#bicycle_brand').value;
					if (brand.length > 15) {
						errors.push('La marque ne doit pas dépasser 15 caractères');
					}
			
					// Vérification du numéro d'identification
					const idNumber = document.querySelector('#bicycle_identification_number').value;
					if (idNumber.length > 25) {
						errors.push('Le numéro d\'identification ne doit pas dépasser 25 caractères');
					}
			
					if (errors.length > 0) {
						event.preventDefault();
						alert('Veuillez corriger les erreurs suivantes :\n\n' + errors.join('\n'));
					}
				});
			}


	// Fonctions utilitaires
	function createErrorDiv(inputElement) {
		const errorDiv = document.createElement('div');
		errorDiv.className = 'validation-error';
		errorDiv.style.color = '#971717';
		errorDiv.style.fontSize = '0.875rem';
		errorDiv.style.marginTop = '0.25rem';
		errorDiv.style.display = 'none';
		inputElement.parentNode.insertBefore(errorDiv, inputElement.nextSibling);
		return errorDiv;
	}

	function showError(errorDiv, message) {
		errorDiv.textContent = message;
		errorDiv.style.display = 'block';
		errorDiv.previousElementSibling.classList.add('is-invalid');
	}

	function hideError(errorDiv) {
		errorDiv.style.display = 'none';
		errorDiv.previousElementSibling.classList.remove('is-invalid');
	}
};
	// Ajoutez également une validation avant la soumission du formulaire

export function displayValidationMessage(isValid, errorMessage) {
	const validationMessage = document.querySelector("#error-price-validation-message");
	if (!isValid) {
		validationMessage.textContent = errorMessage; // Affiche le message d'erreur
		validationMessage.style.display = "block"; // Rendre visible le message d'erreur
	} else {
		validationMessage.textContent = ""; // Efface le message d'erreur
		validationMessage.style.display = "none"; // Cache le message d'erreur
	}
}
 
export function getDescriptionForCondition(condition) {
	switch(condition) {
		case "comme_neuf":
			return "Le vélo est en condition impeccable, presque indiscernable d'un vélo neuf. Il a été très peu utilisé, sans aucune marque visible d'usure ou de dommage. Toutes les composantes fonctionnent parfaitement.";
		case "très_bon_état":
			return "Le vélo montre de très légères traces d'utilisation. Il peut avoir de minuscules éraflures ou marques, mais rien qui affecte sa performance ou son apparence de manière significative. Toutes les parties mécaniques sont en excellent état de fonctionnement.";
		case "bon_état":
			return "Le vélo présente des signes d'utilisation régulière avec quelques éraflures, rayures ou marques visibles, mais sans dommage majeur. Les composantes fonctionnent bien, mais certaines pièces pourraient nécessiter un entretien mineur (par exemple, ajustement des freins ou des vitesses).";
		case "usé":
			return "Le vélo a été clairement utilisé de manière intensive et présente des signes d'usure considérables, tels que des éraflures, des rayures, et potentiellement des petits dommages. Bien qu'il soit toujours en état de fonctionner, des réparations ou remplacements de certaines pièces peuvent être nécessaires pour assurer sa pleine fonctionnalité.";
		default:
			return "Sélectionnez une condition pour voir la description.";
	}
}