import { initializeFormValidation, getDescriptionForCondition, displayValidationMessage } from './featureForm';

const formSelector = '#new-bicycle-form';

const degressiveRates = {
    vélo_de_ville: { weekly: 25 },
    vélo_électrique: { weekly: 25 },
    vélo_de_randonnées: { weekly: 20 },
    vélo_tout_terrain: { weekly: 25 },
    bmx: { weekly: 15 },
    tandem: { weekly: 20 },
    vélo_cargo: { weekly: 20 },
    vélo_de_route: { weekly: 15 },
    vélo_pignon_fixe: { weekly: 25 },
    vélo_longtail: { weekly: 20 },
    gravel: { weekly: 20 },
    vélo_enfant: { weekly: 25 },
    autre: { weekly: 15 },
    porte_vélo: { weekly: 15 },
    autres_accessoires: { weekly: 15 },
    parking: { weekly: 15 }
  };

	document.addEventListener("DOMContentLoaded", function() {
		if (document.querySelector('#new-bicycle-form')) {
			initialize();
			initializeFormValidation(formSelector);
		}
	});
	
	document.addEventListener("turbo:load", function() {
		if (document.querySelector('#new-bicycle-form')) {
			initialize();
			initializeFormValidation(formSelector);
		}
	});
	
	document.addEventListener("turbo:render", function() {
		if (document.querySelector('#new-bicycle-form')) {
			initialize();
			initializeFormValidation(formSelector);
		}
	});

  function initialize() {
    const priceField = document.querySelector("#price_per_hour");
    const categoryField = document.querySelector("#bicycle_type");
    const priceForHalfDayField = document.querySelector("#price_for_half_day");
    const priceFor2DaysField = document.querySelector("#price_for_2_days");
    const priceFor1WeekField = document.querySelector("#price_for_1_week");
    const priceFor2WeeksField = document.querySelector("#price_for_2_weeks");
    const priceFor1MonthField = document.querySelector("#price_for_1_month");
    const validationMessage = document.querySelector("#price-validation-message");
    const weeklyRateField = document.querySelector("#weekly_rate");

    const initialPrices = {
      price_for_half_day: priceForHalfDayField.value,
      price_for_2_days: priceFor2DaysField.value,
      price_for_1_week: priceFor1WeekField.value,
      price_for_2_weeks: priceFor2WeeksField.value,
      price_for_1_month: priceFor1MonthField.value,
      weekly_rate: weeklyRateField.value
    };

  function updatePrices() {
    const pricePerDay = parseFloat(priceField.value);
    const category = categoryField.value;
    const customWeeklyRate = parseFloat(weeklyRateField.value);

    if (!isNaN(pricePerDay) && category && degressiveRates[category]) {
        const rates = {
        weekly: customWeeklyRate || degressiveRates[category].weekly
      };

      const priceForHalfDay = calculateHalfDayPrice(pricePerDay);
      const priceFor2Days = calculateDiscountedPrice(pricePerDay, 2, rates);
      const priceFor1Week = calculateDiscountedPrice(pricePerDay, 7, rates);
      const priceFor2Weeks = calculateDiscountedPrice(pricePerDay, 14, rates);
      const priceFor1Month = calculateMonthlyPrice(pricePerDay, rates);

      priceForHalfDayField.value = priceForHalfDay.toFixed(1);
      priceFor2DaysField.value = priceFor2Days.toFixed(1);
      priceFor1WeekField.value = priceFor1Week.toFixed(1);
      priceFor2WeeksField.value = priceFor2Weeks.toFixed(1);
      priceFor1MonthField.value = priceFor1Month.toFixed(1);

      initialPrices.price_for_half_day = priceForHalfDayField.value;
      initialPrices.price_for_2_days = priceFor2DaysField.value;
      initialPrices.price_for_1_week = priceFor1WeekField.value;
      initialPrices.price_for_2_weeks = priceFor2WeeksField.value;
      initialPrices.price_for_1_month = priceFor1MonthField.value;

      hideSuggestions();
    }
  }

  function updateWeeklyRate() {
    const category = categoryField.value;
    if (category && degressiveRates[category]) {
      const initialRate = degressiveRates[category].weekly;
      weeklyRateField.value = initialRate;
      weeklyRateField.setAttribute("min", 0);
      weeklyRateField.setAttribute("max", 50);
      initialPrices.weekly_rate = initialRate;
      updatePrices();
    }
  }

  function removeSuggestion(event) {
    const targetFieldId = event.target.id;
    const suggestionSpan = document.querySelector(`#suggestion-${targetFieldId}`);
    const resetButton = event.target.parentElement.querySelector(".reset-price");

    if (suggestionSpan) suggestionSpan.style.display = "none";
    if (resetButton) resetButton.style.display = "inline";
  }

  function hideSuggestions() {
    document.querySelectorAll(".price-suggestion").forEach(span => {
      span.style.display = "inline";
    });
    document.querySelectorAll(".reset-price").forEach(button => {
      button.style.display = "none";
    });
  }

  function resetPrice(event) {
    const targetFieldId = event.target.closest("button").dataset.target;
    const targetField = document.querySelector(`#${targetFieldId}`);
    if (targetField && initialPrices[targetFieldId] !== undefined) {
      targetField.value = initialPrices[targetFieldId];

      // Recalculate prices if the reset target is the weekly rate
      if (targetFieldId === "weekly_rate") {
        updatePrices(); // Recalculate all prices based on the reset weekly rate
      }

      const suggestionSpan = document.querySelector(`#suggestion-${targetFieldId}`);
      const resetButton = targetField.parentElement.querySelector(".reset-price");
      if (suggestionSpan) {
        suggestionSpan.style.display = "inline";
      }
      if (resetButton) {
        resetButton.style.display = "none";
      }

      const { isValid, errorMessage } = validatePrices();
      displayValidationMessage(isValid, errorMessage);
    }
  }

if (priceField && categoryField) {
    priceField.addEventListener("input", updatePrices);
    categoryField.addEventListener("change", () => {
      updateWeeklyRate();
      updatePrices();
    });
  }

  if (weeklyRateField) {
    weeklyRateField.addEventListener("input", updatePrices);
    weeklyRateField.addEventListener("input", removeSuggestion);
  }

  priceForHalfDayField.addEventListener("input", () => {
    removeSuggestion(event);
    const { isValid, errorMessage } = validatePrices();
    displayValidationMessage(isValid, errorMessage);
  });

  priceFor2DaysField.addEventListener("input", () => {
    removeSuggestion(event);
    const { isValid, errorMessage } = validatePrices();
    displayValidationMessage(isValid, errorMessage);
  });

  priceFor1WeekField.addEventListener("input", () => {
    removeSuggestion(event);
    const { isValid, errorMessage } = validatePrices();
    displayValidationMessage(isValid, errorMessage);
  });

  priceFor2WeeksField.addEventListener("input", () => {
    removeSuggestion(event);
    const { isValid, errorMessage } = validatePrices();
    displayValidationMessage(isValid, errorMessage);
  });

  priceFor1MonthField.addEventListener("input", () => {
    removeSuggestion(event);
    const { isValid, errorMessage } = validatePrices();
    displayValidationMessage(isValid, errorMessage);
  });


  document.querySelectorAll(".reset-price").forEach(button => {
    button.addEventListener("click", resetPrice);
  });

  // Initial call to set prices if fields are already filled
  updateWeeklyRate();
  updatePrices();
}

	const selectElement = document.getElementById('conditionSelect');
		if (selectElement) {
			selectElement.addEventListener('change', function() {
				const selectedCondition = this.value;
				const description = getDescriptionForCondition(selectedCondition);
				document.getElementById('conditionDescription').innerHTML = '<div class="accordion-body">' + description + '</div>';
			});

			selectElement.dispatchEvent(new Event('change'));
		}

	const addButton = document.getElementById('add-accessories-btn');
	if (addButton) {
		addButton.addEventListener('click', function() {
			let selectedAccessories = [];
			document.querySelectorAll("#accessoriesModal .form-check input:checked").forEach(function(checkbox) {
				let label = checkbox.nextElementSibling.innerText;
				selectedAccessories.push(label);
			});

			let displayDiv = document.getElementById('accessory-show');
			displayDiv.innerHTML = selectedAccessories.map(function(accessory) {
				return `<span class="badge bg-secondary">${accessory}</span>`;
			}).join(' ');
		});
	}

	document.addEventListener('DOMContentLoaded', function() {
		const form = document.getElementById('new-bicycle-form');
		if (form) {
			form.addEventListener('submit', function(event) {
				const latitude = document.getElementById('latitude-input').value;
				const longitude = document.getElementById('longitude-input').value;

			// Vérification des coordonnées GPS
			if (!latitude || !longitude) {
				event.preventDefault(); // Empêche la soumission du formulaire
				alert('Veuillez sélectionner une adresse dans les propositions pour inclure les coordonnées GPS.');
				return; // Assure que la soumission du formulaire est arrêtée
			}

			// Mise à jour des prix avant soumission
			updatePricesBeforeSubmit();

			// Validation des prix
			const { isValid, errorMessage } = validatePrices();

			if (!isValid) {
				event.preventDefault();
				displayValidationMessage(isValid, errorMessage);
				alert('Veuillez vérifier la cohérence des prix proposés.');
			} else {
				displayValidationMessage(isValid, "");
			}
		});
	}
});

	function updatePricesBeforeSubmit() {
		const category = document.querySelector("#bicycle_type").value;

		if (!document.querySelector("#price_for_half_day").value) {
			document.querySelector("#price_for_half_day").value = calculateHalfDayPrice(parseFloat(document.querySelector("#price_per_hour").value)).toFixed(1);
		}
		if (!document.querySelector("#price_for_2_days").value) {
			document.querySelector("#price_for_2_days").value = calculateDiscountedPrice(parseFloat(document.querySelector("#price_per_hour").value), 2, { weekly: parseFloat(document.querySelector("#weekly_rate").value) || degressiveRates[category].weekly }).toFixed(1);
		}
		if (!document.querySelector("#price_for_1_week").value) {
			document.querySelector("#price_for_1_week").value = calculateDiscountedPrice(parseFloat(document.querySelector("#price_per_hour").value), 7, { weekly: parseFloat(document.querySelector("#weekly_rate").value) || degressiveRates[category].weekly }).toFixed(1);
		}
		if (!document.querySelector("#price_for_2_weeks").value) {
			document.querySelector("#price_for_2_weeks").value = calculateDiscountedPrice(parseFloat(document.querySelector("#price_per_hour").value), 14, { weekly: parseFloat(document.querySelector("#weekly_rate").value) || degressiveRates[category].weekly }).toFixed(1);
		}
		if (!document.querySelector("#price_for_1_month").value) {
			document.querySelector("#price_for_1_month").value = calculateMonthlyPrice(parseFloat(document.querySelector("#price_per_hour").value), { weekly: parseFloat(document.querySelector("#weekly_rate").value) || degressiveRates[category].weekly }).toFixed(1);
		}
	}

	function calculateMonthlyPrice(basePrice, rates) {
		const priceForFirstWeek = calculateDiscountedPrice(basePrice, 7, rates);
		const priceForSecondWeek = Math.ceil(7 * basePrice * 0.5);
		const priceFor2Weeks = priceForFirstWeek + priceForSecondWeek;

		if (basePrice < 9) {
			return parseFloat((priceFor2Weeks * 2 * 0.85).toFixed(1))
		} else {
			return Math.ceil(priceFor2Weeks * 2 * 0.85);
		}
	}

	function calculateHalfDayPrice(basePrice) {
		const HOURS_IN_HALF_DAY = 12;

		if (basePrice < 9) {
			return parseFloat(((HOURS_IN_HALF_DAY * basePrice / 24) * 1.4).toFixed(1))
		} else {
		return Math.ceil((HOURS_IN_HALF_DAY * basePrice / 24) * 1.4);
		}
	}

	function calculateDiscountedPrice(basePrice, days, rates) {
		let totalPrice = 0;

		if (days > 1) {
			let currentDayPrice;

			if (basePrice < 9) {
				currentDayPrice = parseFloat((basePrice).toFixed(1));
			} else {
				currentDayPrice = Math.ceil(basePrice);
			}

			for (let i = 0; i < Math.min(7, days); i++) {
				totalPrice += currentDayPrice;
				if (rates && rates.weekly !== undefined) {
					let weeklyRate = rates.weekly / 100;
					if (basePrice < 9) {
						currentDayPrice = parseFloat(Math.ceil(currentDayPrice * (1 - weeklyRate) * 10) / 10);
					} else {
						currentDayPrice = Math.ceil(currentDayPrice * (1 - weeklyRate));
					}
				}
			}

			if (days > 7) {
				if (basePrice < 9) {
					totalPrice += (days - 7) * basePrice * 0.5;
				} else {
				totalPrice += Math.ceil((days - 7) * basePrice * 0.5);
				}
			}
		} else {
			totalPrice = basePrice;
		}

		return totalPrice;
	}

	document.addEventListener('DOMContentLoaded', function() {
		const weeklyRateInput = document.getElementById('weekly_rate');

		if (weeklyRateInput) {
			const invalidFeedback = weeklyRateInput.nextElementSibling;

			weeklyRateInput.addEventListener('input', function() {
				if (weeklyRateInput.checkValidity()) {
					invalidFeedback.style.display = 'none';
				} else {
					invalidFeedback.style.display = 'block';
				}
			});
		}
	});


	document.addEventListener("DOMContentLoaded", function() {
		// Activer les tooltips sur les icônes d'information
		var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'))
		var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
			return new bootstrap.Tooltip(tooltipTriggerEl)
		});

		// Gérer l'ouverture au clic pour les appareils mobiles
		tooltipTriggerList.forEach(function(tooltipTriggerEl) {
			tooltipTriggerEl.addEventListener('click', function() {
				// Activer le tooltip manuellement sur mobile au clic
				var tooltip = bootstrap.Tooltip.getInstance(tooltipTriggerEl);
				if (tooltip._element.classList.contains('show')) {
					tooltip.hide();
				} else {
					tooltip.show();
				}
			});
		});
	});

	function validatePrices() {
		const oneDayPriceField = document.querySelector("#price_per_hour");
		const priceForHalfDayField = document.querySelector("#price_for_half_day");
		const priceFor2DaysField = document.querySelector("#price_for_2_days");
		const priceFor1WeekField = document.querySelector("#price_for_1_week");
		const priceFor2WeeksField = document.querySelector("#price_for_2_weeks");
		const priceFor1MonthField = document.querySelector("#price_for_1_month");
		const halfDayPrice = parseFloat(priceForHalfDayField.value);
		const oneDayPrice = parseFloat(oneDayPriceField.value);
		const twoDaysPrice = parseFloat(priceFor2DaysField.value);
		const oneWeekPrice = parseFloat(priceFor1WeekField.value);
		const twoWeeksPrice = parseFloat(priceFor2WeeksField.value);
		const oneMonthPrice = parseFloat(priceFor1MonthField.value);

		let isValid = true;
		let errorMessage = "";

		if (oneDayPrice < halfDayPrice) {
			isValid = false;
			errorMessage += "Le prix pour une demi journée ne peut pas être supérieur au prix pour 1 jour.\n";
		}
		if (twoDaysPrice < oneDayPrice) {
			isValid = false;
			errorMessage += "Le prix pour 2 jours ne peut pas être inférieur au prix pour 1 jour.\n";
		} 
		if (oneWeekPrice < twoDaysPrice) {
			isValid = false;
			errorMessage += "Le prix pour 1 semaine ne peut pas être inférieur au prix pour 2 jours.\n";
		}
		if (twoWeeksPrice < oneWeekPrice) {
			isValid = false;
			errorMessage += "Le prix pour 2 semaines ne peut pas être inférieur au prix pour 1 semaine.\n";
		}
		if (oneMonthPrice < twoWeeksPrice) {
			isValid = false;
			errorMessage += "Le prix pour 1 mois ne peut pas être inférieur au prix pour 2 semaines.\n";
		}

		return { isValid, errorMessage };
	}

	document.addEventListener('DOMContentLoaded', function() {
		const form = document.querySelector('#new-bicycle-form');
  	if (!form) return;

		const imageInput = document.querySelector('input[type="file"]');
		if (imageInput) {

		// On écoute les changements sur le champ image
			imageInput.addEventListener('change', function(event) {
				const files = event.target.files;
				const compressedFilesPromises = [];

				Array.from(files).forEach((file) => {
					// Crée une promesse pour chaque fichier compressé
					compressedFilesPromises.push(new Promise((resolve, reject) => {
						new Compressor(file, {
							quality: 0.8,
							maxWidth: 1024, // On peut limiter la largeur maximum (optionnel)
							success(result) {
								// Renommer la variable 'file' à 'compressedFile' pour éviter la collision de nom
								if (result instanceof Blob) {
									const compressedFile = new File([result], file.name, { type: result.type });
									resolve(compressedFile);
								} else {
									console.error('Le résultat de la compression n\'est pas un Blob:', result);
									reject(new Error('Le résultat de la compression n\'est pas un Blob'));
								}
							},
							error(err) {
								console.log('Erreur lors de la compression :', err.message);
								reject(err);
							},
						});
					}));
				});

			// Attendre que toutes les compressions soient terminées
			Promise.all(compressedFilesPromises).then((compressedFiles) => {
				const dataTransfer = new DataTransfer(); // Pour manipuler les fichiers
				compressedFiles.forEach(compressedFile => {
					if (compressedFile instanceof File) {
						dataTransfer.items.add(compressedFile);
					} else {
						console.error('Le résultat de la compression n\'est pas un fichier valide:', compressedFile);
					}
				});

				// Remplacer les fichiers du champ input par les fichiers compressés
				imageInput.files = dataTransfer.files;
			}).catch((error) => {
				console.error('Erreur lors de la compression des fichiers:', error);
			});
		});
		}
	});


	document.addEventListener('DOMContentLoaded', function() {
		const form = document.querySelector('#new-bicycle-form');
  	if (!form) return;

		const input = document.getElementById('bicycle_images');

		if (input) {
			input.addEventListener('change', function() {
				localStorage.setItem(
					'bicycle_images',
					JSON.stringify([...input.files].map(file => URL.createObjectURL(file)))
				);
			});

			const savedFiles = JSON.parse(localStorage.getItem('bicycle_images') || '[]');
			savedFiles.forEach(url => {
				const img = document.createElement('img');
				img.src = url;
				img.style.width = '100px';
				document.body.appendChild(img);
			});

			// Nettoyer le stockage local lorsque le formulaire est correctement soumis
			document.getElementById('new-bicycle-form').addEventListener('submit', function() {
				localStorage.removeItem('bicycle_images');
			});
		}
	});

	document.addEventListener('DOMContentLoaded', function() {
		const form = document.querySelector('#new-bicycle-form');
  	if (!form) return;

		const input = document.getElementById('bicycle_images');
		if (input) {
			input.addEventListener('change', function() {
				let previewContainer = document.getElementById('image-preview-container');
				
				if (!previewContainer) {
					previewContainer = document.createElement('div');
					previewContainer.id = 'image-preview-container';
					document.body.insertBefore(previewContainer, input.nextSibling);
				}
				
				previewContainer.innerHTML = ''; // Efface les aperçus précédents

				for (const file of input.files) {
					const reader = new FileReader();
					reader.onload = function(e) {
						const img = document.createElement('img');
						img.src = e.target.result;
						img.style.width = '100px'; // Ajustez la taille si nécessaire
						previewContainer.appendChild(img);
					};
					reader.readAsDataURL(file);
				}
			});
		}
	});

